import { IconCheck, IconPlus } from "@tabler/icons-react";
import { ISalary } from "../../interfaces/api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFetch } from "../../api/api"

interface ISalaryModalProp {
    salary: ISalary | undefined
    onUpdate: () => void
}

export default function IncomeModal({ salary, onUpdate }: ISalaryModalProp) {
    const [date, setDate] = useState("")
    const [fixedIncome, setFixedIncome] = useState(0)
    const [variableIncome, setVariableIncome] = useState(0)
    const isNewSalary = !salary || !salary?.date
    const { fetch_api } = useFetch()

    useEffect(() => {
        if (!salary) {
            setDate(new Date().toISOString().substring(0, 10))
            setFixedIncome(0)
            setVariableIncome(0)
        }
        else {
            setDate(salary.date)
            setFixedIncome(salary.fixed_amount)
            setVariableIncome(salary.var_amount)
        }
    }, [salary])

    const save = async () => {
        let url = isNewSalary ? `salary` : `salary/${salary.date}`
        let method = isNewSalary ? "POST" : "PUT"
        let res = await fetch_api(url, {
            method: method, headers: {
                "Content-Type": "application/json"
            }, body: JSON.stringify({
                date: date,
                fixed_amount: fixedIncome,
                var_amount: variableIncome
            })
        })
        if (res.ok) {
            toast("Income " + (isNewSalary ? "created" : "updated"), { type: "success" })
            onUpdate()
        }
    }

    return (
        <div className="modal modal-blur fade" id="salary-modal" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{isNewSalary ? "New" : "Edit"} salary</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label className="form-label">Date</label>
                            <div className="input-icon mb-2">
                              <input className="form-control " placeholder="Select a date" value={date} type="date" onChange={(e) => setDate(e.target.value)} />
                            </div>
                        </div>
                        <div className="row">
                        <div className="col">
                            <label className="form-label">Fixed Income</label>
                            <div className="input-group mb-2">
                              <span className="input-group-text">
                                $
                              </span>
                              <input className="form-control " placeholder="Fixed income" type="number" value={fixedIncome} onChange={(e) => setFixedIncome(e.target.valueAsNumber)} />
                            </div>
                        </div>
                        <div className="col">
                            <label className="form-label">Variable Income</label>
                            <div className="input-group mb-2">
                              <span className="input-group-text">
                                $
                              </span>
                              <input className="form-control " placeholder="Variable income" type="number" value={variableIncome} onChange={(e) => setVariableIncome(e.target.valueAsNumber) } />
                            </div>
                        </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        <button onClick={() => save()} className="btn btn-primary ms-auto" data-bs-dismiss="modal">
                            {isNewSalary ? <IconPlus className="icon" /> : <IconCheck className="icon" />}
                            {isNewSalary ? "Create" : "Edit"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}