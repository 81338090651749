import { IFixedExpense, IFixedExpenseHistory, IFixedExpenseHistoryItem } from "../../interfaces/api";
import { useEffect, useState } from "react";
import { CURRENCY_FORMAT, SHORT_DMY_DATE_FORMAT } from "../../consts";
import { useFetch } from "../../api/api";

interface IFixedExpenseModalProp {
    fixed_expense_history: IFixedExpenseHistoryItem
    onUpdate: () => void
}

export default function FixedExpenseHistoryModalRow({ fixed_expense_history, onUpdate }: IFixedExpenseModalProp) {
    const { fetch_api } = useFetch()
    const [ editMode, setEditModeInternal ] = useState(false)
    const [ fixedExpenseHistory, setFixedExpenseHistory ] = useState<IFixedExpenseHistoryItem>(fixed_expense_history)

    const setEditMode = async (value: boolean, update: boolean) => {
        setEditModeInternal(value)
        if (!value && update) {
            var response = await fetch_api(`fixed_expenses/historial/${fixedExpenseHistory.id}`, 
                { method: "PUT", 
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    pay_date: fixedExpenseHistory.pay_date,
                    amount: fixedExpenseHistory.amount
                }) 
            })
            if (!response.ok)
                update = false;
        }
        if(!update) {
            setFixedExpenseHistory(fixed_expense_history)
        }
    }
    const deleteEntry = async (id: number) => {
        if (!window.confirm("Are you sure you want to delete the fixed expense history?"))
            return;
        await fetch_api(`fixed_expenses/historial/${id}`, { method: "DELETE" })
        onUpdate()
    }

    const payDate = editMode ? 
        <input value={fixedExpenseHistory.pay_date} className="form-control " placeholder="Pay date" type="date" onChange={(e) => { setFixedExpenseHistory({ ...fixedExpenseHistory, pay_date: e.target.value }) }}  /> :
        <span>{fixedExpenseHistory.pay_date ? SHORT_DMY_DATE_FORMAT.format(Date.parse(fixedExpenseHistory.pay_date + "T00:00:00.000-03:00")) : ""}</span>

    const amount = editMode ?
        <div className="input-group mb-2">
            <span className="input-group-text">
            $
            </span>
            <input value={fixedExpenseHistory.amount} className="form-control " placeholder="Amount" type="number" onChange={(e) => { setFixedExpenseHistory({ ...fixedExpenseHistory, amount: e.target.valueAsNumber }) }}  />
        </div> :
        <span>{CURRENCY_FORMAT.format(fixedExpenseHistory.amount)}</span>

    return (
        <tr>
            <td>{payDate}</td>
            <td>{amount}</td>
            <td><a href="#" onClick={() => { setEditMode(!editMode, true)  }}>{editMode ? 'Done' : 'Edit'}</a></td>
            {editMode ? 
                <td><a href="#" className="text-danger" onClick={() => { setEditMode(!editMode, false) }}>Cancel</a></td> :
                <td><a href="#" className="text-danger" onClick={() => { deleteEntry(fixed_expense_history.id) }}>Delete</a></td>
            }
        </tr>
    )
}