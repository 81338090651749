import { IconCheck, IconPlus } from "@tabler/icons-react";
import { IAccount, IMovement } from "../../interfaces/api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFetch } from "../../api/api";

interface IMovementModalProp {
    movement: IMovement | undefined
    account: number | undefined
    onUpdate: () => void
}

export default function MovementModal({ movement, account, onUpdate }: IMovementModalProp) {
    const [accounts, setAccounts] = useState<IAccount[]>([])
    const [name, setName] = useState(movement?.name)
    const [date, setDate] = useState(movement?.date)
    const [amount, setAmount] = useState(movement?.amount)
    const [accountId, setAccountId] = useState(account)
    const isNewMovement = !movement || movement?.id === -1
    const { fetch_api } = useFetch()

    useEffect(() => {
        if (!movement) {
            setName("")
            setAccountId(account)
            setDate(new Date().toISOString().substring(0, 10))
            setAmount(undefined)
        }
        else {
            setName(movement.name)
            setAccountId(movement.account_id)
            setDate(movement.date)
            setAmount(movement.amount)
        }
    }, [movement])

    useEffect(() => {
        (async () => {
            let accounts = (await fetch_api(`accounts`)).data
            setAccounts(accounts)
        })();
    }, [])

    const save = async () => {
        let url = isNewMovement ? `movements` : `movements/${movement.id}`
        let method = isNewMovement ? "POST" : "PUT"
        let res = await fetch_api(url, {
            method: method, headers: {
                "Content-Type": "application/json"
            }, body: JSON.stringify({
                name: name,
                amount: amount,
                date: date,
                account_id: accountId
            })
        })
        if (res.ok) {
            toast("Movement " + (isNewMovement ? "created" : "updated"), { type: "success" })
            setName("")
            onUpdate()
        }
    }

    let accounts_options = accounts.map((x) => (
        <option key={x.id} value={x.id}>{x.name}</option>
    ))

    return (
        <div className="modal modal-blur fade" id="movement-modal" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{isNewMovement ? "New" : "Edit"} movement</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label className="form-label">Name</label>
                            <input type="text" className="form-control" name="example-text-input" placeholder="Movement name" onChange={(e) => setName(e.target.value)} value={name} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Account</label>
                            <select className="form-select" value={accountId} onChange={(e) => setAccountId(parseInt(e.target.value))}>
                                <option key={-1} disabled value={-1}>Select an account</option>
                                {accounts_options}
                            </select>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label className="form-label">Amount</label>
                                <div className="input-group">
                                    <span className="input-group-text">
                                        $
                                    </span>
                                    <input type="number" className="form-control" name="example-text-input" placeholder="Movement amount" onChange={(e) => setAmount(e.target.valueAsNumber)} value={amount} />
                                </div>
                            </div>
                            <div className="col">
                                <label className="form-label">Date</label>
                                <input type="date" className="form-control" name="example-text-input" onChange={(e) => setDate(e.target.value)} value={date} />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        <button onClick={() => save()} className="btn btn-primary ms-auto" data-bs-dismiss="modal">
                            {isNewMovement ? <IconPlus className="icon" /> : <IconCheck className="icon" />}
                            {isNewMovement ? "Create" : "Edit"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}