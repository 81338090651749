import { useEffect, useState } from "react"
import { CURRENCY_FORMAT, SHORT_DMY_DATE_FORMAT } from "../../consts"
import { IAccount, IMovement } from "../../interfaces/api"
import { toast } from "react-toastify"
import { IconPlus, IconReport } from "@tabler/icons-react"
import MovementModal from "./MovementModal"
import InvoiceModal from "./InvoiceModal"
import { useFetch } from "../../api/api"

export default function Movements() {
    const [accounts, setAccounts] = useState<IAccount[]>([])
    const [accountId, setAccountId] = useState<number>(-1)
    const [update, setUpdate] = useState(1)
    const [movements, setMovements] = useState<IMovement[]>([])
    const [selectedMovement, setSelectedMovement] = useState<IMovement>()
    const { fetch_api } = useFetch()
    
    useEffect(() => {
        (async () => {
            let accounts = (await fetch_api(`accounts`)).data
            setAccounts(accounts)
        })();
    }, [update])

    useEffect(() => {
        (async () => {
            let movements = (await fetch_api(`movements/account/${accountId}`)).data
            setMovements(movements)
        })();
    }, [accountId, update])

    const onDeleteMovementClick = async (movementId: number) => {
        if (!window.confirm("Are you sure you want to delete the movement?"))
            return;
        var res = await fetch_api(`movements/${movementId}`, { method: "DELETE" })
        if (res.ok) {
            toast("Movement deleted", { type: "success" })
            setUpdate(update + 1)
        }
    }

    let accounts_options = accounts.map((x) => (
        <option value={x.id}>{x.name}</option>
    ))

    let movements_rows = movements.map((x) => (
        <tr>
            <td>{SHORT_DMY_DATE_FORMAT.format(Date.parse(x.date + "T00:00:00.000-03:00")) }</td>
            <td>{x.name}</td>
            <td>{CURRENCY_FORMAT.format(x.amount)}</td>
            <td><a href="#" onClick={() => {setSelectedMovement(x)}} data-bs-toggle="modal" data-bs-target="#movement-modal">Edit</a></td>
            <td><a href="#" onClick={() => {onDeleteMovementClick(x.id)}} className="text-danger">Delete</a></td>
        </tr>
    ))

    let total_row = (
        <tr className="text-secondary">
            <td></td>
            <td>Total</td>
            <td>{CURRENCY_FORMAT.format(movements.map((x)=>x.amount).reduce((x,y)=>x+y,0))}</td>
            <td></td>
            <td></td>
        </tr>
    )

    return (
        <>
        <div className="page-wrapper">
            <div className="page-header d-print-none">
                <div className="container-xl">
                    <div className="row g-2 align-items-center">
                        <div className="col">
                            <div className="page-pretitle">
                                Overview
                            </div>
                            <h2 className="page-title">
                                Movements
                            </h2>
                        </div>
                        <div className="col-auto ms-auto d-print-none">
                            <div className="btn-list">
                                    <a href="#" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#invoice-modal"> 
                                        <IconReport className="icon" />
                                        Generate report
                                    </a>
                                <a href="#" className="btn btn-primary" onClick={() => {setSelectedMovement(undefined)}} data-bs-toggle="modal" data-bs-target="#movement-modal">
                                    <IconPlus className="icon" />
                                    Add new
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-body">
                <div className="container-xl">
                    <div className="row row-cards">
                        <div className="col">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label className="form-label">Account</label>
                                        <select className="form-select" value={accountId} onChange={(e) => setAccountId(parseInt(e.target.value))}>
                                            <option disabled value={-1}>Select an account</option>
                                            {accounts_options}
                                        </select>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-vcenter card-table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Name</th>
                                                    <th>Amount</th>
                                                    <th className="w-1"></th>
                                                    <th className="w-1"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {movements_rows}
                                                {total_row}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MovementModal movement={selectedMovement} account={accountId} onUpdate={()=> {setUpdate(update + 1)}} />
        <InvoiceModal />
        </>
    )
}