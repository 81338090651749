import { useEffect, useState } from "react"
import { CURRENCY_FORMAT, SHORT_DMY_DATE_FORMAT } from "../../consts"
import { IBank, ICard, ICardClosing } from "../../interfaces/api"
import { toast } from "react-toastify"
import { IconPencil, IconPlus, IconTrash } from "@tabler/icons-react"
import BankModal from "./BankModal"
import CardModal from "./CardModal"
import ClosingModal from "./ClosingModal"
import { useFetch } from "../../api/api"


export default function Banks() {
    const { fetch_api } = useFetch()
    const [banks, setBanks] = useState<IBank[]>([])
    const [cards, setCards] = useState<ICard[]>([])
    const [cardClosings, setCardClosings] = useState<ICardClosing[]>([])
    const [update, setUpdate] = useState(1)

    const [selectedBank, setSelectedBank] = useState<IBank>()
    const [selectedCard, setSelectedCard] = useState<ICard>()
    const [selectedCardClosing, setSelectedCardClosing] = useState<ICardClosing>()

    useEffect(() => {
        (async () => {
            let banks = (await fetch_api(`banks`)).data
            setBanks(banks)
        })();
        (async () => {
            if (selectedBank && selectedBank?.id > 0) {
                let cards = (await fetch_api(`cards/by_bank/${selectedBank.id}`)).data
                setCards(cards)
            }
        })();
        (async () => {
            if (selectedCard && selectedCard.id > 0) {
                let cards_closings = (await fetch_api(`cards/${selectedCard.id}/closings`)).data
                setCardClosings(cards_closings)
            }
        })();
    }, [update])

    useEffect(() => {
        setCardClosings([])
        if (!selectedBank || selectedBank?.id === -1) {
            setCards([])
            return
        }
        (async () => {
            let cards = (await fetch_api(`cards/by_bank/${selectedBank.id}`)).data
            setCards(cards)
        })()
    }, [selectedBank])

    useEffect(() => {
        if (!selectedCard || selectedCard.id === -1) {
            setCardClosings([])
            return
        }
        (async () => {
            let card_closings = (await fetch_api(`cards/${selectedCard.id}/closings`)).data
            setCardClosings(card_closings)
        })()
    }, [selectedCard])

    const onDeleteBankClick = async (bankId: number) => {
        if (!window.confirm("Are you sure you want to delete the bank?"))
            return;
        var res = await fetch_api(`banks/${bankId}`, { method: "DELETE" })
        if (res.ok) {
            toast("Bank deleted", { type: "success" })
            setUpdate(update + 1)
        }
    }

    const onDeleteCardClick = async (cardId: number) => {
        if (!window.confirm("Are you sure you want to delete the card?"))
            return;
        var res = await fetch_api(`cards/${cardId}`, { method: "DELETE" })
        if (res.ok) {
            toast("Card deleted", { type: "success" })
            setUpdate(update + 1)
        }
    }
    const onDeleteCardClosingClick = async (cardId: number, cardClosingId: number) => {
        if (!window.confirm("Are you sure you want to delete the card closing?"))
            return;
        var res = await fetch_api(`cards/${cardId}/closings/${cardClosingId}`, { method: "DELETE" })
        if (res.ok) {
            toast("Card closing deleted", { type: "success" })
            setUpdate(update + 1)
        }
    }

    let banks_rows = banks.map((x) => (
        <div key={x.id} className={"list-group-item " + (selectedBank?.id === x.id ? "active" : "")}>
            <div className="row align-items-center">
                <div className="col-auto">
                    <a href="#">
                        <span className="avatar"></span>
                    </a>
                </div>
                <div className="col text-truncate">
                    <a href="#" onClick={() => setSelectedBank(x)} className="text-reset d-block">{x.name}</a>
                </div>
                <div className="col-auto">
                    <a href="#" className="list-group-item-actions" onClick={() => { setSelectedBank(x) }} data-bs-toggle="modal" data-bs-target="#bank-modal">
                        <IconPencil className="icon" />
                    </a>
                    <a href="#" className="list-group-item-actions" onClick={() => { onDeleteBankClick(x.id) }}>
                        <IconTrash className="icon" />
                    </a>
                </div>
            </div>
        </div>
    ))

    let cards_rows = cards.map((x) => (
        <div key={x.id} className={"list-group-item " + (selectedCard?.id === x.id ? "active" : "")}>
            <div className="row align-items-center">
                <div className="col-auto">
                    <a href="#">
                        <span className="avatar"></span>
                    </a>
                </div>
                <div className="col text-truncate">
                    <a href="#" onClick={(e) => setSelectedCard(x)} className="text-reset d-block">{x.name}</a>
                    <div className="d-block text-secondary text-truncate mt-n1">Credit limit: {CURRENCY_FORMAT.format(x.limit)}</div>
                </div>
                <div className="col-auto">
                    <a href="#" className="list-group-item-actions" onClick={() => { setSelectedCard(x) }} data-bs-toggle="modal" data-bs-target="#card-modal">
                        <IconPencil className="icon" />
                    </a>
                    <a href="#" className="list-group-item-actions" onClick={() => { onDeleteCardClick(x.id) }}>
                        <IconTrash className="icon" />
                    </a>
                </div>
            </div>
        </div>
    ))

    let closing_due_rows = cardClosings.map((x) => (
        <div key={x.id} className="list-group-item">
            <div className="row align-items-center">
                <div className="col text-truncate">
                    <a href="#" className="text-reset d-block">Closing date: {SHORT_DMY_DATE_FORMAT.format(Date.parse(x.closing_date + "T00:00:00.000-03:00"))}</a>
                    <div className="d-block text-secondary text-truncate mt-n1">Due date: {SHORT_DMY_DATE_FORMAT.format(Date.parse(x.due_date + "T00:00:00.000-03:00"))}</div>
                </div>
                <div className="col-auto">
                    <a href="#" className="list-group-item-actions" onClick={() => { setSelectedCardClosing(x) }} data-bs-toggle="modal" data-bs-target="#card-closing-modal">
                        <IconPencil className="icon" />
                    </a>
                    <a href="#" className="list-group-item-actions" onClick={() => { onDeleteCardClosingClick(x.card_id, x.id) }}>
                        <IconTrash className="icon" />
                    </a>
                </div>
            </div>
        </div>
    ))

    return (
        <>
            <div className="page-wrapper">
                <div className="page-header d-print-none">
                    <div className="container-xl">
                        <div className="row g-2 align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                    Overview
                                </div>
                                <h2 className="page-title">
                                    Banks & Cards
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <div className="row row-cards">
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Banks</h4>
                                        <div className="card-actions">
                                            <a href="#" className="btn btn-primary" onClick={() => { setSelectedBank(undefined) }} data-bs-toggle="modal" data-bs-target="#bank-modal">
                                                <IconPlus className="icon" />
                                                Add new
                                            </a>
                                        </div>
                                    </div>
                                    <div className="list-group list-group-flush list-group-hoverable">
                                        {banks_rows}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Cards</h4>
                                        <div className="card-actions">
                                            <button className="btn btn-primary" onClick={() => { setSelectedCard(undefined) }} data-bs-toggle="modal" data-bs-target="#card-modal" disabled={!selectedBank || selectedBank?.id === -1}>
                                                <IconPlus className="icon" />
                                                Add new
                                            </button>
                                        </div>
                                    </div>
                                    <div className="list-group list-group-flush list-group-hoverable">
                                        {!selectedBank || selectedBank?.id === -1 ? <div className="m-3">Select a bank first</div> : cards_rows}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Closing & Due dates</h4>
                                        <div className="card-actions">
                                            <button className="btn btn-primary" onClick={() => { setSelectedCardClosing(undefined) }} data-bs-toggle="modal" data-bs-target="#card-closing-modal" disabled={!selectedCard || selectedCard?.id === -1}>
                                                <IconPlus className="icon" />
                                                Add new
                                            </button>
                                        </div>
                                    </div>
                                    <div className="list-group list-group-flush list-group-hoverable">
                                        {!selectedCard || selectedCard?.id === -1 ? <div className="m-3">Select a card first</div> : closing_due_rows}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BankModal bank={selectedBank} onUpdate={() => { setUpdate(update + 1) }} />
            <CardModal card={selectedCard} bank={selectedBank} onUpdate={() => { setUpdate(update + 1) }} />
            <ClosingModal card={selectedCard} cardClosing={selectedCardClosing} onUpdate={() => { setUpdate(update + 1) }} />
        </>
    )
}
