import { IconCheck, IconPlus } from "@tabler/icons-react";
import { IBank } from "../../interfaces/api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFetch } from "../../api/api"

interface IBankModalProp {
    bank: IBank | undefined
    onUpdate: () => void
}

export default function BankModal({ bank, onUpdate }: IBankModalProp) {
    const [bankName, setBankName] = useState("")
    const { fetch_api } = useFetch()
    const isNewBank = !bank || bank?.id === -1
    useEffect(() => {
        if (!bank)
            setBankName("")
        else
            setBankName(bank.name)
    }, [bank])

    const save = async () => {
        let url = isNewBank ? `banks` : `banks/${bank.id}`
        let method = isNewBank ? "POST" : "PUT"
        let res = await fetch_api(url, {
            method: method, headers: {
                "Content-Type": "application/json"
            }, body: JSON.stringify({
                name: bankName,
            })
        })
        if (res.ok) {
            toast("Bank " + (isNewBank ? "created" : "updated"), { type: "success" })
            setBankName("")
            onUpdate()
        }
    }

    return (
        <div className="modal modal-blur fade" id="bank-modal" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{isNewBank ? "New" : "Edit"} bank</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label className="form-label">Name</label>
                            <input type="text" className="form-control" placeholder="Your bank name" onChange={(e) => setBankName(e.target.value)} value={bankName} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        <button onClick={() => save()} className="btn btn-primary ms-auto" data-bs-dismiss="modal">
                            {isNewBank ? <IconPlus className="icon" /> : <IconCheck className="icon" />}
                            {isNewBank ? "Create" : "Edit"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}