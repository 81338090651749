import { useEffect, useState } from "react";
import { CURRENCY_FORMAT, SHORT_DMY_DATE_FORMAT, SHORT_MY_DATE_FORMAT } from "../../consts";
import { IAccount, IDebtor, IMovement } from "../../interfaces/api";
import { useFetch } from "../../api/api";
import MovementModal from "../movements/MovementModal";

export default function Debtors() {
    const [update, setUpdate] = useState(0)
    const [debtors, setDebtors] = useState<IDebtor[]>([])
    const [account, setAccount] = useState<IAccount>()
    const [movement, setMovement] = useState<IMovement>()
    const { fetch_api } = useFetch()

    useEffect(() => {
        (async () => {
            let _debtors = (await fetch_api('debtors')).data
            setDebtors(_debtors)
        })()
    }, [update])

    const settleUp = (account: IAccount, amount: number) => {
        setAccount(account)
        let new_movement = {
            account_id: account.id,
            amount: parseFloat(amount.toFixed(2)),
            date: new Date().toISOString().slice(0, 10),
            name: "Pago",
            id: -1
        }
        setMovement(new_movement)
    }

    let debt_row = debtors?.map((x) => (
        <tr key={x.account.name}>
            <td>{x.account.name}</td>
            <td>{CURRENCY_FORMAT.format(x.amount)}</td>
            <td><button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#movement-modal" onClick={_ => { settleUp(x.account, x.amount) }}>Settle up</button></td>
        </tr>
    ))

    return (
        <>
            <div className="row row-cards pb-2">
                <div className="col">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Debtors</h4>
                        </div>
                        <div className="card-body table-responsive">
                            <table className="table table-vcenter card-table">
                                <thead>
                                    <tr>
                                        <th>Account</th>
                                        <th>Debt value</th>
                                        <th className="w-1">Operations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {debt_row}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <MovementModal account={account?.id} movement={movement} onUpdate={() => setUpdate(update + 1)} />
        </>
    )
}