import { IconPlus } from "@tabler/icons-react";
import { IBank, ICard } from "../../interfaces/api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFetch } from "../../api/api"

interface ICardModalProp {
    card: ICard | undefined
    bank: IBank | undefined
    onUpdate: () => void
}

export default function CardModal({ bank, card, onUpdate }: ICardModalProp) {
    const [cardName, setCardName] = useState(card?.name)
    const [cardLimit, setCardLimit] = useState(card?.limit)
    const isNewCard = !card || card?.id === -1
    const { fetch_api } = useFetch()

    useEffect(() => {
        if (isNewCard) {
            setCardName("")
            setCardLimit(0)
        }
        else {
            setCardLimit(card.limit)
            setCardName(card.name)
        }
    }, [card])

    const save = async () => {
        let url = isNewCard ? `cards` : `cards/${card.id}`
        let method = isNewCard ? "POST" : "PUT"
        let res = await fetch_api(url, {
            method: method, headers: {
                "Content-Type": "application/json"
            }, body: JSON.stringify({
                name: cardName,
                limit: cardLimit,
                bank_id: bank?.id
            })
        })
        if (res.ok) {
            toast("Card " + (isNewCard ? "created" : "updated"), { type: "success" })
            setCardLimit(0)
            setCardName("")
            onUpdate()
        }
        else
            toast(`Error: ${res.data["detail"]}`, { type: "error" })

    }

    return (
        <div className="modal modal-blur fade" id="card-modal" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{!card || card?.id === -1 ? "New" : "Edit"} card</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label className="form-label">Name</label>
                            <input type="text" className="form-control" name="example-text-input" value={cardName} onChange={(e) => setCardName(e.target.value)} placeholder="Your card name" />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Limit</label>
                            <div className="input-group mb-2">
                                <span className="input-group-text">
                                    $
                                </span>
                                <input type="number" className="form-control" placeholder="Your limit amount" value={cardLimit} onChange={(e) => setCardLimit(e.target.valueAsNumber)} autoComplete="off" />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        <a href="#" onClick={() => save()} className="btn btn-primary ms-auto" data-bs-dismiss="modal">
                            <IconPlus className="icon" />
                            {!card || card?.id === -1 ? "Create" : "Edit"}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}