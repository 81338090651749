import { IAccount } from "../../interfaces/api";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFetch } from "../../api/api";
import { Link, useNavigate } from "react-router-dom";

export default function InvoiceModal() {
    const [accounts, setAccounts] = useState<IAccount[]>([])
    const [name, setName] = useState("")
    const [date, setDate] = useState("")
    const [accountId, setAccountId] = useState(-1)
    const navigate = useNavigate()
    const { fetch_api } = useFetch()
    
    useEffect(() => {
        (async () => {
            let accounts = (await fetch_api(`accounts`)).data
            setAccounts(accounts)
        })();
    }, [])

    let accounts_options = accounts.map((x) => (
        <option value={x.id}>{x.name}</option>
    ))
    const onGenerateClick = (e: React.MouseEvent) => {
        if (!(date && accountId)) {
            e.preventDefault()
            toast("You must fill all the data", { type: "error" })
        }
        else {
            navigate(`/invoice/${accountId}/${date}`)
        }
    }

    return (
        <div className="modal modal-blur fade" id="invoice-modal" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Generate invoice</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label className="form-label">Account</label>
                            <select className="form-select" value={accountId} onChange={(e) => setAccountId(parseInt(e.target.value))}>
                                <option disabled value={-1}>Select an account</option>
                                {accounts_options}
                            </select>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label className="form-label">Date</label>
                                <input type="date" className="form-control" name="example-text-input" onChange={(e) => setDate(e.target.value)} value={date} />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        <Link to="/" className="btn btn-primary ms-auto" onClick={(e) => onGenerateClick(e)} data-bs-dismiss="modal">
                            Generate invoice
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}