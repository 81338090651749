import { IFixedExpense, IFixedExpenseHistory, IFixedExpenseHistoryItem } from "../../interfaces/api";
import { useEffect, useState } from "react";
import { CURRENCY_FORMAT, SHORT_DMY_DATE_FORMAT } from "../../consts";
import { useFetch } from "../../api/api";
import FixedExpenseHistoryModalRow from "./FixedExpenseHistoryModalRow";

interface IFixedExpenseModalProp {
    fixed_expense: IFixedExpense | undefined
    onUpdate: () => void
}

export default function FixedExpenseHistoryModal({ fixed_expense, onUpdate }: IFixedExpenseModalProp) {
    const { fetch_api } = useFetch()
    const [history, setHistory] = useState<IFixedExpenseHistoryItem[]>()
    const [update, setUpdate] = useState(0)

    useEffect(() => {
        if (fixed_expense && fixed_expense.id) {
            (async () => {
                let fixed_expenses = (await fetch_api(`fixed_expenses/${fixed_expense.id}`)).data as IFixedExpenseHistory
                setHistory(fixed_expenses.fixed_expense_history)
            })()
        }
    }, [fixed_expense, update])

    const _onUpdate = () => {
        onUpdate()
        setUpdate(update + 1)
    }

    let history_row = history?.map((x) => (
        <FixedExpenseHistoryModalRow fixed_expense_history={x} key={x.id} onUpdate={_onUpdate} />
    ))

    return (
        <div className="modal modal-blur fade" id="fixed-expense-history-modal" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{fixed_expense?.name}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <table className="table table-vcenter card-table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th className="w-1"></th>
                                    <th className="w-1"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    history_row
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <a href="#" className="btn btn-link link-secondary ms-auto" data-bs-dismiss="modal">
                            Close
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}