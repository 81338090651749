import { useEffect, useState } from "react";
import { CURRENCY_FORMAT, SHORT_DMY_DATE_FORMAT } from "../../consts";
import { IFixedExpense } from "../../interfaces/api";
import { useFetch } from "../../api/api";
import PayExpenseModal from "./PayExpenseModal";

export default function PendingExpenses() {
    const [update, setUpdate] = useState(0)
    const [fixedExpenses, setFixedExpenses] = useState<IFixedExpense[]>([])
    const [ selectedFixedExpense, setSelectedFixedExpense ] = useState<IFixedExpense | undefined>(undefined)
    const { fetch_api } = useFetch()

    useEffect(() => {
        (async () => {
            let expenses = (await fetch_api('fixed_expenses/pending')).data
            setFixedExpenses(expenses)
        })()
    }, [update])

    let debt_row = fixedExpenses?.map((x) => {
        let due_date = new Date()
        due_date.setDate(x.due_day)
        return <tr key={x.id}>
            <td>{x.name}</td>
            <td>{x.due_day ? SHORT_DMY_DATE_FORMAT.format(due_date) : "Not set"}</td>
            <td>{CURRENCY_FORMAT.format(x.amount)}</td>
            <td><button className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#pay-expense-modal" onClick={_ => { setSelectedFixedExpense(x)  }}>Pay</button></td>
        </tr>
    })

    return (
        <>
            <div className="row row-cards pb-2">
                <div className="col">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Pending expenses</h4>
                        </div>
                        <div className="card-body table-responsive">
                            <table className="table table-vcenter card-table">
                                <thead>
                                    <tr>
                                        <th>Expense</th>
                                        <th>Due date</th>
                                        <th>Last expenditure</th>
                                        <th className="w-1">Operations</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {debt_row}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <PayExpenseModal fixed_expense={selectedFixedExpense} onUpdate={() => {setUpdate(update + 1)}} />
        </>
    )
}