import { useEffect, useState } from "react"
import { useFetch } from "../../api/api"
import { Link, useNavigate } from "react-router-dom"
import { IAccount } from "../../interfaces/api"
import { toast } from "react-toastify"
import { useAuth0 } from "@auth0/auth0-react"

export default function Accounts() {
    const {getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
    const [accounts, setAccounts] = useState<IAccount[]>([])
    const [update, setUpdate] = useState(1)
    const { fetch_api } = useFetch()
    const navigate = useNavigate();
    useEffect(() => {
        (async () => {
            let tok =  await getAccessTokenSilently()
            let accounts = await (await fetch_api(`accounts`)).data
            setAccounts(accounts)
        })()
    }, [update])

    const onDeleteClick = async (accountId: number) => {
        if (!window.confirm("Are you sure you want to delete the account?"))
            return;
        var res = await fetch_api(`accounts/${accountId}`, { method: "DELETE" })
        if (res.ok) {
            toast("Account deleted", { type: "success" })
            setUpdate(update + 1)
        }
    }

    let accounts_rows = accounts.map((x) => (
        <tr key={x.id}>
            <td>{x.name}</td>
            <td className="text-secondary">
                <a href={`mailto:${x.email}`} className="text-reset">{x.email}</a>
            </td>
            <td className="text-secondary">
                <a href={`mailto:${x.phone}`} className="text-reset">{x.phone}</a>
            </td>
            <td>
                <Link to={`/accounts/${x.id}`}>
                    Edit
                </Link>
            </td>
            <td>
                <a className="text-danger" href="#" onClick={() => onDeleteClick(x.id)}>
                    Delete
                </a>
            </td>
        </tr>
    ))
    return (
        <div className="page-wrapper">
            <div className="page-header d-print-none">
                <div className="container-xl">
                    <div className="row g-2 align-items-center">
                        <div className="col">
                            <div className="page-pretitle">
                                Overview
                            </div>
                            <h2 className="page-title">
                                Accounts
                            </h2>
                        </div>
                        <div className="col-auto ms-auto d-print-none">
                            <div className="btn-list">
                                <span className="d-sm-inline">
                                    <Link to={'/accounts/new'} className="btn">
                                        New Account
                                    </Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-body">
                <div className="container-xl">
                    <div className="row row-cards">
                        <div className="col">
                            <div className="card">
                                <div className="table-responsive">
                                    <table className="table table-vcenter card-table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th className="w-1"></th>
                                                <th className="w-1"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {accounts_rows}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}