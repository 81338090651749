import { useEffect, useState } from "react"
import { CURRENCY_FORMAT, SHORT_DMY_DATE_FORMAT, SHORT_MY_DATE_FORMAT } from "../../consts"
import { useParams } from "react-router-dom"
import { IAccount, IMovement } from "../../interfaces/api"
import { useFetch } from "../../api/api"
import { IconPrinter } from "@tabler/icons-react"

export default function Invoice() {
    const { accountId, date } = useParams()
    const [account, setAccount] = useState<IAccount>()
    const [movements, setMovements] = useState<IMovement[]>([])
    const [total, setTotal] = useState<IMovement>()
    const { fetch_api } = useFetch()
    
    useEffect(() => {
        (async () => {
            let movements = (await fetch_api(`movements/account/${accountId}/date/${date}`)).data
            setMovements(movements)
        })();
        (async () => {
            let account = (await fetch_api(`accounts/${accountId}`)).data
            setAccount(account)
        })();
    }, [])



    let movements_rows = movements.map((x)=>(
        <tr>
            <td>{ x.date ? SHORT_DMY_DATE_FORMAT.format(Date.parse(x.date + "T00:00:00.000-03:00")) : "" }</td>
            <td>{ x.name }</td>
            <td></td>
            <td className="text-end">{ CURRENCY_FORMAT.format(x.amount) }</td>
        </tr>
    ))

    let total_row = (
        <tr>
            <td></td>
            <td></td>
            <td><p className="strong">TOTAL</p></td>
            <td className="text-end strong">{ CURRENCY_FORMAT.format(movements.map((x) => x.amount).reduce((x,y)=>x+y, 0))}</td>
        </tr>
    )

    return (
        <div className="page-wrapper">
            <div className="page-header d-print-none">
                <div className="container-xl">
                    <div className="row g-2 align-items-center">
                        <div className="col">
                            <div className="page-pretitle">
                                Overview
                            </div>
                            <h2 className="page-title">
                                Invoice
                            </h2>
                        </div>
                        <div className="col-auto ms-auto d-print-none">
                            <div className="btn-list">
                                <span className="d-sm-inline">
                                    <a href='#' className="btn btn-primary" onClick={_=> {window.print()}}>
                                        <IconPrinter className="icon"/>
                                        Print
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-body">
                <div className="container-xl">
                    <div className="card card-lg">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6">
                                    <h1>Banco Nico</h1>
                                </div>
                                <div className="col-6 text-end">
                                    <p className="h3">{account?.name}</p>
                                    <address>
                                        {account?.email}<br/>
                                        {account?.phone}
                                    </address>
                                </div>
                                <div className="col-12 my-5">
                                    <h2>Resumen {SHORT_MY_DATE_FORMAT.format(Date.parse(date + "T00:00:00.000-03:00"))}</h2>
                                </div>
                            </div>
                            <table className="table table-transparent table-responsive">
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{ width: "15em" }}>Fecha</th>
                                        <th>Descripcion</th>
                                        <th></th>
                                        <th className="text-end">Subtotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {movements_rows}
                                    {total_row}
                                </tbody></table>
                            <p className="text-secondary text-center mt-5">Gracias por sus compras, vuelvas prontos</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}