import FixedExpenses from "./FixedExpenses"
import Income from "./Income"

export default function Personal() {

    return (
        <>
            <div className="page-wrapper">
                <div className="page-header d-print-none">
                    <div className="container-xl">
                        <div className="row g-2 align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                    Overview
                                </div>
                                <h2 className="page-title">
                                    Personal budget
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <Income />
                        <FixedExpenses />
                    </div>
                </div>
            </div>
        </>
    )
}