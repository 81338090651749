import { toast } from "react-toastify";
import { HOST } from "../consts";
import { useAuth0 } from "@auth0/auth0-react";

export function useFetch() {
    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()
    const host = HOST

    return {
        fetch_api: async (url: string, init?: RequestInit | undefined) => {
            if (!isAuthenticated || isLoading) {
                return {
                    ok: false,
                    data: {}
                }
            }
            if (!init)
                init = {}
            if (!init.headers)
                init.headers = {}
            let token = await getAccessTokenSilently({
                authorizationParams: {
                    scope: 'sys'
                }
            })
            init.headers = { ...init.headers, "Authorization": `Bearer ${token}` }

            let response = await fetch(`${host}/${url}`, init)

            if (response.ok) {
                let json = await response.json()
                return {
                    ok: true,
                    data: json
                }
            }
            let text = await response.text()
            toast(`API Error ${response.status}: ${text}`, { type: "error" })
            return {
                ok: false,
                data: text
            }
        }
    }

}