import { IconHistory, IconPlus } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { CURRENCY_FORMAT, SHORT_MY_DATE_FORMAT } from "../../consts";
import { ISalary } from "../../interfaces/api";
import IncomeModal from "./IncomeModal";
import { useFetch } from "../../api/api";

export default function Income() {
    const [update, setUpdate] = useState(0)
    const [historyEnabled, setHistoryEnabled] = useState(false)
    const [salary, setSalary] = useState<ISalary[]>([])
    const [selectedSalary, setSelectedSalary] = useState<ISalary>()
    const { fetch_api } = useFetch()

    useEffect(() => {
        (async () => {
            let url = historyEnabled ? `salary` : `salary/last`
            let salary = (await fetch_api(url)).data
            salary = historyEnabled ? salary : [salary]
            setSalary(salary)
        })()
    }, [update])

    let salary_row = salary?.map((x) => (
        <tr key={x.date}>
            <td>{SHORT_MY_DATE_FORMAT.format(Date.parse(x.date + "T00:00:00.000-03:00"))}</td>
            <td>{CURRENCY_FORMAT.format(x.fixed_amount)}</td>
            <td>{CURRENCY_FORMAT.format(x.var_amount)}</td>
            <td>{CURRENCY_FORMAT.format(x.fixed_amount + x.var_amount)} </td>
            <td><a href="#" data-bs-toggle="modal" data-bs-target="#salary-modal" onClick={() => { setSelectedSalary(x) }}>Edit</a></td>
        </tr>
    ))

    return (
        <>
            <div className="row row-cards pb-2">
                <div className="col">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Income</h4>
                            <div className="card-actions">
                                <div className="btn-list">
                                    <button className="btn" onClick={() => { setHistoryEnabled(!historyEnabled); setUpdate(update + 1) }}>
                                        <IconHistory className="icon" />
                                        Show {historyEnabled ? "last" : "history"}
                                    </button>
                                    <a href="#" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#salary-modal" onClick={() => setSelectedSalary(undefined)}>
                                        <IconPlus className="icon" />
                                        Add new
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="card-body table-responsive">
                            <table className="table table-vcenter card-table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Fixed income</th>
                                        <th>Variable income</th>
                                        <th>Total income</th>
                                        <th className="w-1"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {salary_row}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <IncomeModal salary={selectedSalary} onUpdate={() => { setUpdate(update + 1) }} />
        </>
    )
}