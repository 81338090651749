import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { IAccount } from "../../interfaces/api"
import { IconCheck, IconX } from "@tabler/icons-react"
import { toast } from "react-toastify"
import { useFetch } from "../../api/api"


export default function Accounts() {
    const { fetch_api } = useFetch()
    const { accountId } = useParams()
    const isNewAccount = accountId?.toLowerCase() === "new"
    const navigate = useNavigate();
    const [name, setName] = useState<string | undefined>("")
    const [email, setEmail] = useState<string | undefined>("")
    const [phone, setPhone] = useState<string | undefined>("")
    useEffect(() => {
        (async () => {
            if ( isNewAccount)
                return
            let account = (await fetch_api(`accounts/${accountId}`)).data as IAccount
            setName(account?.name)
            setEmail(account?.email)
            setPhone(account?.phone)
        })()
    }, [])

    const save = async () => 
    {
        let url = isNewAccount ? `accounts` : `accounts/${accountId}`
        let method = isNewAccount ? "POST" : "PUT"
        let res = await fetch_api(url, { method: method, headers: {
            "Content-Type": "application/json"
        }, body: JSON.stringify({
                name: name,
                email: email,
                phone: phone
            }) 
        })
        if (res.ok) {
            toast("Account " + (isNewAccount ? "created" : "updated"), { type: "success" } )
            navigate("/accounts")
        }
    }

    return (
        <div className="page-wrapper">
            <div className="page-header d-print-none">
                <div className="container-xl">
                    <div className="row g-2 align-items-center">
                        <div className="col">
                            <div className="page-pretitle">
                                { isNewAccount ? "Create" : "Edit" }
                            </div>
                            <h2 className="page-title">
                                Account
                            </h2>
                        </div>
                        <div className="col-auto ms-auto d-print-none">
                            <div className="btn-list">
                                <span className="d-sm-inline">
                                    <a href="#" onClick={() => save() } className="btn btn-primary">
                                        <IconCheck className="icon" />
                                        Save
                                    </a>
                                </span>
                                <span className="d-sm-inline">
                                    <a href="#" onClick={() => navigate("/accounts") } className="btn btn-danger">
                                        <IconX className="icon" />
                                        Cancel
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-body">
                <div className="container-xl">
                    <div className="row row-cards">
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">Username</label>
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Username" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Phone</label>
                                <input type="phone" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control" data-mask="(000) 0000000" placeholder="Phone" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}