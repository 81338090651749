import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { IAccount, ICard, IPurchase } from "../../interfaces/api"
import { IconCheck, IconX } from "@tabler/icons-react"
import { toast } from "react-toastify"
import { useFetch } from "../../api/api"


export default function Purchase() {
    const { purchaseId } = useParams()
    const isNewAccount = !purchaseId || purchaseId?.toLowerCase() === "new"
    const navigate = useNavigate();
    const [id, setId] = useState(-1)
    const [name, setName] = useState("")
    const [buy_date, setBuyDate] = useState(new Date().toISOString().substring(0, 10))
    const [end_payment_date, setEndPaymentDate] = useState("")
    const [amount, setAmount] = useState<number | undefined>()
    const [installments, setInstallments] = useState<number>(1)
    const [account_id, setAccountId] = useState(1)
    const [card_id, setCardId] = useState<number | undefined>(-1)
    const [cards, setCards] = useState<ICard[]>([])
    const [accounts, setAccounts ] = useState<IAccount[]>([])
    const { fetch_api } = useFetch()


    useEffect(() => {
        (async () => {
            if (isNewAccount)
                return
            let purchase = (await fetch_api(`purchases/${purchaseId}`)).data as IPurchase
            setName(purchase?.name)
            setBuyDate(purchase?.buy_date)
            setEndPaymentDate(purchase?.end_payment_date)
            setAmount(purchase?.amount)
            setInstallments(purchase?.installments)
            setAccountId(purchase?.account_id)
            setCardId(purchase?.card_id)
        })();
        
        (async () => {
            let cards = (await fetch_api(`cards`)).data as ICard[]
            setCards(cards)
        })();
        
        (async () => {
            let accounts = (await fetch_api(`accounts`)).data as IAccount[]
            setAccounts(accounts)
        })();
    }, [purchaseId])

    const save = async () => {
        let url = isNewAccount ? `purchases` : `purchases/${purchaseId}`
        let method = isNewAccount ? "POST" : "PUT"
        let res = await fetch_api(url, {
            method: method, headers: {
                "Content-Type": "application/json"
            }, body: JSON.stringify({
                name: name,
                buy_date: buy_date, 
                amount: amount,
                installments: installments,
                account_id: account_id,
                card_id: card_id
            })
        })
        if (res.ok) {
            toast("Purchase " + (isNewAccount ? "created" : "updated"), { type: "success" })
            navigate("/purchases")
        }
        else
            toast(`Error: ${(res.data)["detail"]}`, { type: "error" })

    }

    let cards_options = cards.map((x) => (
        <option key={x.id} value={x.id}>{x.name} {x.bank?.name}</option>
    ))

    let accounts_options = accounts.map((x) => (
        <option key={x.id} value={x.id}>{x.name}</option>
    ))

    return (
        <div className="page-wrapper">
            <div className="page-header d-print-none">
                <div className="container-xl">
                    <div className="row g-2 align-items-center">
                        <div className="col">
                            <div className="page-pretitle">
                                {isNewAccount ? "Create" : "Edit"}
                            </div>
                            <h2 className="page-title">
                                Purchase
                            </h2>
                        </div>
                        <div className="col-auto ms-auto d-print-none">
                            <div className="btn-list">
                                <span className="d-sm-inline">
                                    <a href="#" onClick={() => save()} className="btn btn-primary">
                                        <IconCheck className="icon" />
                                        Save
                                    </a>
                                </span>
                                <span className="d-sm-inline">
                                    <a href="#" onClick={() => navigate("/purchases")} className="btn btn-danger">
                                        <IconX className="icon" />
                                        Cancel
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-body">
                <div className="container-xl">
                    <div className="row row-cards">
                        <div className="col">
                            <div className="mb-3">
                                <label className="form-label">Description</label>
                                <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Purchase description" />
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="mb-3">
                                        <label className="form-label">Amount</label>
                                        <div className="input-group mb-2">
                                            <span className="input-group-text">
                                                $
                                            </span>
                                            <input type="number" value={amount} onChange={(e) => setAmount(e.target.valueAsNumber)} className="form-control" placeholder="Purchase amount" />
                                        </div>

                                    </div>
                                </div>
                                <div className="col">
                                    <div className="mb-3">
                                        <label className="form-label">Buy Date</label>
                                        <input type="date" value={buy_date} onChange={(e) => setBuyDate(e.target.value)} className="form-control" placeholder="Buy Date" />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="mb-3">
                                        <label className="form-label">Installments</label>
                                        <input type="number" value={installments} onChange={(e) => setInstallments(e.target.valueAsNumber)} className="form-control" placeholder="Purchase installments" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="mb-3">
                                        <label className="form-label">Account</label>
                                        <select className="form-select" value={account_id} onChange={(e) => setAccountId(parseInt(e.target.value))}>
                                            {accounts_options}
                                        </select>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="mb-3">
                                        <label className="form-label">Card</label>
                                        <select className="form-select" value={card_id} onChange={(e) => setCardId(parseInt(e.target.value))}>
                                            <option value={-1} disabled>Choose a card</option>
                                            {cards_options}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}