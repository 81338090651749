import { useEffect, useState } from "react"
import { CURRENCY_FORMAT, SHORT_DMY_DATE_FORMAT, SHORT_MY_DATE_FORMAT } from "../../consts"
import { Link } from "react-router-dom"
import { IAccount, IPurchase, ISummary } from "../../interfaces/api"
import { toast } from "react-toastify"
import { IconHistory, IconHistoryOff, IconPlus } from "@tabler/icons-react"
import { useFetch } from "../../api/api";

export default function Purchases() {
    const [purchases, setPurchases] = useState<IPurchase[]>([])
    const [accounts, setAccounts] = useState<IAccount[]>([])
    const [update, setUpdate] = useState(1)
    const [getAll, setGetAll] = useState(false)
    const [accountId, setAccountId] = useState<number>(-1)
    const [summary, setSummary] = useState<ISummary>()
    const [dateFrom, setDateFrom] = useState<string>(new Date().toISOString().substring(0,10))
    const [months, setMonths] = useState<number>(6)
    const { fetch_api } = useFetch()

    useEffect(() => {
        (async () => {
            let url = `purchases` + (getAll ? "?all=true" : "")
            let purchases = (await fetch_api(url)).data
            setPurchases(purchases)
        })();
        (async () => {
            let accounts = (await fetch_api(`accounts`)).data
            setAccounts(accounts)
        })();
    }, [update, getAll])

    useEffect(() => {
        if (!accountId || accountId === -1)
            return
        (async () => {
            let url = `purchases/by_month/${accountId}`
            let urlParams = []
            if (dateFrom)
                urlParams.push(`dateFrom=${dateFrom}`)
            if (months)
                urlParams.push(`months=${months}`)
            url = urlParams ? `${url}?${urlParams.join('&')}` : url
            let summary = (await fetch_api(url)).data
            setSummary(summary)
        })();
    }, [accountId, dateFrom, months])

    const onDeletePurchaseClick = async (purchaseId: number) => {
        if (!window.confirm("Are you sure you want to delete the purchase?"))
            return;
        var res = await fetch_api(`purchases/${purchaseId}`, { method: "DELETE" })
        if (res.ok) {
            toast("Purchase deleted", { type: "success" })
            setUpdate(update + 1)
        }
    }

    let purchases_rows = purchases.map((x) => (
        <tr key={x.id}>
            <td>{CURRENCY_FORMAT.format(x.amount)}</td>
            <td>{x.name}</td>
            <td>{x.installments}</td>
            <td>{CURRENCY_FORMAT.format(x.amount / x.installments)}</td>
            <td>{SHORT_DMY_DATE_FORMAT.format(Date.parse(x.buy_date + "T00:00:00.000-03:00"))}</td>
            <td>{SHORT_MY_DATE_FORMAT.format(Date.parse(x.end_payment_date + "T00:00:00.000-03:00"))}</td>
            <td>{x.account?.name}</td>
            <td>{`${x.card?.name} ${x.card?.bank?.name}`}</td>
            <td><Link to={`${x.id}`}>Edit</Link></td>
            <td><a href="#" onClick={() => onDeletePurchaseClick(x.id)} className="text-danger">Delete</a></td>
        </tr>
    ))

    let accounts_options = accounts.map((x) => (
        <option key={x.id} value={x.id}>{x.name}</option>
    ))

    let total_row = (
        <tr key={-1} className="text-secondary">
            <td>{CURRENCY_FORMAT.format(purchases.map((x) => x.amount).reduce((x, y) => x + y, 0))}</td>
            <td>Total</td>
            <td></td>
            <td>{CURRENCY_FORMAT.format(purchases.map((x) => x.amount / x.installments).reduce((x, y) => x + y, 0))}</td>
            <td></td>
            <td>{SHORT_MY_DATE_FORMAT.format(purchases.map((x) => Date.parse(x.end_payment_date + "T00:00:00.000-03:00")).reduce((x, y) => x > y ? x : y, 0))}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    )
    
    let card_list_header = summary?.cards.map((x) => (
        <th>{x.name}</th>
    ))

    const summary_safe = summary ? summary.summary : {}
    let summary_rows = Object.keys(summary_safe).map((x, i) => (
        <tr key={i}>
            <td>{SHORT_MY_DATE_FORMAT.format(Date.parse(x + "T00:00:00.000-03:00")) }</td>
            <td>{ CURRENCY_FORMAT.format(summary?.summary[x].reduce((x, y) => x + y, 0) || 0) }</td>
            { summary?.summary[x].map((y, j) => (
                <td key={-j}>{ CURRENCY_FORMAT.format(y) }</td>
            )) }
        </tr>
    ))

    return (
        <>
            <div className="page-wrapper">
                <div className="page-header d-print-none">
                    <div className="container-xl">
                        <div className="row g-2 align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                    Overview
                                </div>
                                <h2 className="page-title">
                                    Purchases
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-fluid">
                        <div className="row row-cards">
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Purchases</h4>
                                        <div className="card-actions">
                                            <div className="btn-list">
                                                <a href="#" className="d-none d-md-inline btn btn-secondary" onClick={_ => setGetAll(!getAll)}>
                                                    {getAll ? <IconHistoryOff className="icon" /> : <IconHistory className="icon" />}
                                                    {getAll ? "Show current purchases" : "Show all purchases"}
                                                </a>
                                                <Link to="new" className="btn btn-primary">
                                                    <IconPlus className="icon" />
                                                    Add new
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-vcenter card-table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Amount</th>
                                                    <th>Name</th>
                                                    <th>Intallments</th>
                                                    <th>Amount / month</th>
                                                    <th>1st installment</th>
                                                    <th>Last installment</th>
                                                    <th>Account</th>
                                                    <th>Card</th>
                                                    <th className="w-1"></th>
                                                    <th className="w-1"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {purchases_rows}
                                                {total_row}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row mb-3">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title">Summary by account</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className="mb-3">
                                                <label className="form-label">Account</label>
                                                <select className="form-select" value={accountId} onChange={(e) => setAccountId(parseInt(e.target.value))}>
                                                    <option disabled value={-1}>Select an account</option>
                                                    {accounts_options}
                                                </select>
                                            </div>
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="mb-3">
                                                        <label className="form-label">Date From</label>
                                                        <input type="date" value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="mb-3">
                                                        <label className="form-label">Months</label>
                                                        <input type="number" value={months} onChange={(e) => setMonths(e.target.valueAsNumber)} className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col table-responsive">
                                                    <table className="table table-hover table-vcenter ">
                                                        <thead>
                                                            <tr>
                                                                <th className="auto">Date</th>
                                                                <th className="auto">Total</th>
                                                                { card_list_header }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            { summary_rows }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}