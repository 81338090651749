import { IconCheck, IconPlus } from "@tabler/icons-react";
import { IFixedExpense } from "../../interfaces/api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFetch } from "../../api/api"

interface IFixedExpenseModalProp {
    fixed_expense: IFixedExpense | undefined
    onUpdate: () => void
}

export default function FixedExpenseModal({ fixed_expense, onUpdate }: IFixedExpenseModalProp) {
    const [dueDay, setDueDay] = useState<number>(1)
    const [payDate, setPayDate] = useState<string>("")
    const [name, setName] = useState("")
    const [amount, setAmount] = useState(0)
    const isNewFixedExpense = !fixed_expense || !fixed_expense?.id
    const { fetch_api } = useFetch()

    useEffect(() => {
        if (!fixed_expense) {
            setPayDate(new Date().toISOString().substring(0, 10))
            setName("")
            setAmount(0)
            setDueDay(1)
        }
        else {
            setPayDate(fixed_expense.pay_date || "")
            setName(fixed_expense.name)
            setAmount(fixed_expense.amount)
            setDueDay(fixed_expense.due_day || 1)
        }
    }, [fixed_expense])

    const save = async () => {
        let url = isNewFixedExpense ? `fixed_expenses` : `fixed_expenses/${fixed_expense?.id}`
        let method = isNewFixedExpense ? "POST" : "PUT"
        let res = await fetch_api(url, {
            method: method, headers: {
                "Content-Type": "application/json"
            }, body: JSON.stringify({
                due_day: dueDay,
                pay_date: payDate,
                name: name,
                amount: amount
            })
        })
        if (res.ok) {
            toast("Fixed expense " + (isNewFixedExpense ? "created" : "updated"), { type: "success" })
            onUpdate()
        }
    }

    return (
        <div className="modal modal-blur fade" id="fixed-expense-modal" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{isNewFixedExpense ? "New" : "Edit"} Fixed Expense</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row mb-3">
                            <div className="col-9">
                                <label className="form-label">Name</label>
                                <div className="input-icon mb-2">
                                    <input className="form-control " placeholder="Fixed expense" value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-3">
                                <label className="form-label">Due Day</label>
                                <div className="input-icon mb-2">
                                    <input className="form-control " placeholder="Enter the due day" value={dueDay} type="number" min={1} max={31} step={1} onChange={(e) => setDueDay(parseInt(e.target.value))} />
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3" style={{ display: isNewFixedExpense ? "" : "none" }}>
                            <div className="col-6">
                                <label className="form-label">Pay Date</label>
                                <div className="input-icon mb-2">
                                    <input className="form-control " placeholder="Select a date" value={payDate} type="date" onChange={(e) => setPayDate(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-6">
                                <label className="form-label">Amount</label>
                                <div className="input-group mb-2">
                                    <span className="input-group-text">
                                        $
                                    </span>
                                    <input className="form-control " placeholder="Amount fixed expense" type="number" value={amount} onChange={(e) => setAmount(e.target.valueAsNumber)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        <button onClick={() => save()} className="btn btn-primary ms-auto" data-bs-dismiss="modal">
                            {isNewFixedExpense ? <IconPlus className="icon" /> : <IconCheck className="icon" />}
                            {isNewFixedExpense ? "Create" : "Edit"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}