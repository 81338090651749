import React, { useEffect } from 'react';
import logo from './logo.svg';
import '@tabler/core/dist/css/tabler.min.css';
import '@tabler/core/dist/js/tabler.min.js'
import TopBar from './components/topbar/TopBar';
import {
  Routes, Route
} from "react-router-dom";
import Home from './pages/home/Home';
import FooterBar from './components/footerbar/FooterBar';
import Accounts from './pages/accounts/Accounts';
import Banks from './pages/banks/Banks';
import Purchases from './pages/purchases/Purchases';
import Movements from './pages/movements/Movements';
import Personal from './pages/personal/Personal';
import Account from './pages/accounts/Account';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Purchase from './pages/purchases/Purchase';
import Invoice from './pages/movements/Invoice';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './Loading';
import { useFetch } from './api/api';

function App() {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const { fetch_api } = useFetch()

  useEffect(() => {
    if (isLoading)
      return
    if (!isAuthenticated)
      loginWithRedirect()
  }, [isLoading, isAuthenticated])

  useEffect(() => {
    const urlB64ToUint8Array = (base64String: string) => {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
      const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')
      const rawData = atob(base64)
      const outputArray = new Uint8Array(rawData.length)
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
      }
      return outputArray
    }
    const applicationServerKey = urlB64ToUint8Array(
      'BKvFHW_ttTgIYP3DsGHncutgQ05-FLXS8fZa6BI0wEmoWQ4U4dJ2u9IdzwpSUEASDhSWQFP2IO-AyrGl3T0v9ys'
    )
    const options = { applicationServerKey, userVisibleOnly: true };
    (async () => {
      const permission = await window.Notification.requestPermission();
      if (permission !== "granted")
        return;
      var swRegistration = await navigator.serviceWorker.getRegistration('sw.js');
      if (!swRegistration) {
        swRegistration = await navigator.serviceWorker.register("sw.js");
      }
      var sub = await swRegistration.pushManager.subscribe(options)
      await fetch_api('subscriptions', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(sub)
      })
    })()
  }, [isAuthenticated])

  return (
    isLoading || !isAuthenticated ? <Loading /> :
      <>
        <TopBar />
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/accounts/:accountId" element={<Account />} />
          <Route path="/banks" element={<Banks />} />
          <Route path="/personal" element={<Personal />} />
          <Route path="/purchases/new" element={<Purchase />} />
          <Route path="/purchases/:purchaseId" element={<Purchase />} />
          <Route path="/purchases" element={<Purchases />} />
          <Route path="/movements" element={<Movements />} />
          <Route path='/invoice/:accountId/:date' element={<Invoice />} />
        </Routes>
        <FooterBar />
        <ToastContainer />
      </>
  )
}

export default App;

