import Debtors from "./Debtors";
import PendingExpenses from "./PendingExpenses";

export default function Home() {

    return (
        <>
            <div className="page-wrapper">
                <div className="page-header d-print-none">
                    <div className="container-xl">
                        <div className="row g-2 align-items-center">
                            <div className="col">
                                <div className="page-pretitle">
                                    Dashboard
                                </div>
                                <h2 className="page-title">
                                    Home
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body">
                    <div className="container-xl">
                        <Debtors />
                        <PendingExpenses />
                    </div>
                </div>
            </div>
        </>
    )
}