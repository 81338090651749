import { IconCheck, IconPlus } from "@tabler/icons-react";
import { IFixedExpense } from "../../interfaces/api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFetch } from "../../api/api"

interface IFixedExpenseModalProp {
    fixed_expense: IFixedExpense | undefined
    onUpdate: () => void
}

export default function PayExpenseModal({ fixed_expense, onUpdate }: IFixedExpenseModalProp) {
    const [payDate, setPayDate] = useState<string>("")
    const [amount, setAmount] = useState(0)
    const { fetch_api } = useFetch()

    useEffect(() => {
        setPayDate(new Date().toISOString().substring(0, 10))
        setAmount(fixed_expense?.amount || 0)
    }, [fixed_expense])

    const save = async () => {
        let res = await fetch_api(`fixed_expenses/${fixed_expense?.id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }, body: JSON.stringify({
                pay_date: payDate,
                amount: amount
            })
        })
        if (res.ok) {
            toast(`${fixed_expense?.name} paid`, { type: "success" })
            onUpdate()
        }
    }

    return (
        <div className="modal modal-blur fade" id="pay-expense-modal" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Pay {fixed_expense?.name}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row mb-3">
                            <div className="col-6">
                                <label className="form-label">Pay Date</label>
                                <div className="input-icon mb-2">
                                    <input className="form-control " placeholder="Select a date" value={payDate} type="date" onChange={(e) => setPayDate(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-6">
                                <label className="form-label">Amount</label>
                                <div className="input-group mb-2">
                                    <span className="input-group-text">
                                        $
                                    </span>
                                    <input className="form-control " placeholder="Amount fixed expense" type="number" value={amount} onChange={(e) => setAmount(e.target.valueAsNumber)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        <button onClick={() => save()} className="btn btn-primary ms-auto" data-bs-dismiss="modal">
                            <IconCheck className="icon" />
                            Pay
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}