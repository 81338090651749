import { IconPlus } from "@tabler/icons-react";
import { IFixedExpense } from "../../interfaces/api";
import { CURRENCY_FORMAT, SHORT_DMY_DATE_FORMAT } from "../../consts";
import { useState, useEffect } from "react";
import FixedExpenseModal from "./FixedExpenseModal";
import FixedExpenseHistoryModal from "./FixedExpenseHistoryModal";
import { toast } from "react-toastify";
import { useFetch } from "../../api/api";

export default function FixedExpenses() {
    const { fetch_api } = useFetch()
    const [update, setUpdate] = useState(0)
    const [fixed_expenses, setFixedExpenses] = useState<IFixedExpense[]>([])
    const [selectedFixedExpense, setSelectedFixedExpense] = useState<IFixedExpense>()

    useEffect(() => {
        (async () => {
            let fixed_expenses = (await fetch_api(`fixed_expenses`)).data
            setFixedExpenses(fixed_expenses)
        })()
    }, [update])

    let fixed_expenses_row = fixed_expenses?.map((x) => (
        <tr key={x.id}>
            <td>{x.name}</td>
            <td>{x.due_day}</td>
            <td>{x.pay_date ? SHORT_DMY_DATE_FORMAT.format(Date.parse(x.pay_date + "T00:00:00.000-03:00")) : ""}</td>
            <td>{CURRENCY_FORMAT.format(x.amount)}</td>
            <td><a href="#" className="text-secondary" data-bs-toggle="modal" data-bs-target="#fixed-expense-history-modal" onClick={() => { setSelectedFixedExpense(x) }}>History</a></td>
            <td><a href="#" data-bs-toggle="modal" data-bs-target="#fixed-expense-modal" onClick={() => { setSelectedFixedExpense(x) }}>Edit</a></td>
            <td><a href="#" className="text-danger" onClick={() => { onDeleteFixedExpenseClick(x.id) }}>Delete</a></td>
        </tr>
    ))
    let total_row = (
        <tr key="total" className="text-secondary">
            <td>Total</td>
            <td></td>
            <td>{SHORT_DMY_DATE_FORMAT.format(new Date())}</td>
            <td>{CURRENCY_FORMAT.format(fixed_expenses.map((x) => x.amount).reduce((x, y) => x + y, 0))}</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
    )

    const onDeleteFixedExpenseClick = async (fixedExpenseId: number) => {
        if (!window.confirm("Are you sure you want to delete the fixed expense?"))
            return;
        var res = await fetch_api(`fixed_expenses/${fixedExpenseId}`, { method: "DELETE" })
        if (res.ok) {
            toast("Fixed expense deleted", { type: "success" })
            setUpdate(update + 1)
        }
    }

    return (
        <>
            <div className="row row-cards">
                <div className="col">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Fixed expenses</h4>
                            <div className="card-actions">
                                <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#fixed-expense-modal" onClick={() => setSelectedFixedExpense(undefined)}>
                                    <IconPlus className="icon" />
                                    Add new
                                </button>
                            </div>
                        </div>
                        <div className="card-body table-responsive">
                            <table className="table table-vcenter card-table">
                                <thead>
                                    <tr>
                                        <th>Expense</th>
                                        <th>Due day</th>
                                        <th>Last update</th>
                                        <th>Amount</th>
                                        <th className="w-1"></th>
                                        <th className="w-1"></th>
                                        <th className="w-1"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        fixed_expenses_row
                                    }
                                    {
                                        total_row
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <FixedExpenseModal fixed_expense={selectedFixedExpense} onUpdate={() => setUpdate(update + 1)} />
            <FixedExpenseHistoryModal fixed_expense={selectedFixedExpense} onUpdate={() => setUpdate(update + 1)} />
        </>
    )
}