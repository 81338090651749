import { IconBuildingBank, IconFilePencil, IconHome2, IconShoppingBag, IconUser, IconWallet } from "@tabler/icons-react";
import { Link } from "react-router-dom";

export default function TopBar() {
    return (
        <>
            <header className="navbar navbar-expand-md d-print-none" >
                <div className="container-xl">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu" aria-controls="navbar-menu" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
                        <a href=".">
                            CC Tracker
                        </a>
                    </h1>
                    <div className="navbar-nav flex-row order-md-last">
                        <div className="d-none d-md-flex">
                            <a href="?theme=dark" className="nav-link px-0 hide-theme-dark" title="Enable dark mode" data-bs-toggle="tooltip"
                                data-bs-placement="bottom">

                                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z" /></svg>
                            </a>
                            <a href="?theme=light" className="nav-link px-0 hide-theme-light" title="Enable light mode" data-bs-toggle="tooltip"
                                data-bs-placement="bottom">

                                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7" /></svg>
                            </a>
                            <div className="nav-item dropdown d-none d-md-flex me-3">
                                <a href="#" className="nav-link px-0" data-bs-toggle="dropdown" tabIndex={-1} aria-label="Show notifications">

                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 5a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" /><path d="M9 17v1a3 3 0 0 0 6 0v-1" /></svg>
                                    <span className="badge bg-red"></span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card">
                                    <div className="card">
                                        {/* TODO: Poner notificaciones */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
                                <span className="avatar avatar-sm">
                                    <IconUser />
                                </span>
                                <div className="d-none d-xl-block ps-2">
                                    <div>User</div>
                                    <div className="mt-1 small text-secondary">Role</div>
                                </div>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                <a href="#" className="dropdown-item">Status</a>
                                <a href="./profile.html" className="dropdown-item">Profile</a>
                                <div className="dropdown-divider"></div>
                                <a href="./settings.html" className="dropdown-item">Settings</a>
                                <a href="./sign-in.html" className="dropdown-item">Logout</a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <header className="navbar-expand-md d-print-none">
                <div className="collapse navbar-collapse" id="navbar-menu">
                    <div className="navbar">
                        <div className="container-xl">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/" >
                                        <span className="nav-link-icon d-md-none d-lg-inline-block">
                                            <IconHome2 className="icon" />
                                        </span>
                                        <span className="nav-link-title">
                                            Home
                                        </span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/accounts" >
                                        <span className="nav-link-icon d-md-none d-lg-inline-block">
                                            <IconUser className="icon" />
                                        </span>
                                        <span className="nav-link-title">
                                            Accounts
                                        </span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/banks" >
                                        <span className="nav-link-icon d-md-none d-lg-inline-block">
                                            <IconBuildingBank className="icon" />
                                        </span>
                                        <span className="nav-link-title">
                                            Banks
                                        </span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/personal" >
                                        <span className="nav-link-icon d-md-none d-lg-inline-block">
                                            <IconWallet className="icon" />
                                        </span>
                                        <span className="nav-link-title">
                                            Personal
                                        </span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/purchases" >
                                        <span className="nav-link-icon d-md-none d-lg-inline-block">
                                            <IconShoppingBag className="icon" />
                                        </span>
                                        <span className="nav-link-title">
                                            Purchases
                                        </span>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/movements" >
                                        <span className="nav-link-icon d-md-none d-lg-inline-block">
                                            <IconFilePencil className="icon" />
                                        </span>
                                        <span className="nav-link-title">
                                            Movements
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}