import { IconPlus } from "@tabler/icons-react";
import { ICard, ICardClosing } from "../../interfaces/api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFetch } from "../../api/api"

interface ICardClosingModalProp {
    cardClosing: ICardClosing | undefined
    card: ICard | undefined
    onUpdate: () => void
}

export default function CardClosingModal({ cardClosing, card, onUpdate }: ICardClosingModalProp) {
    const [closingDate, setClosingDate] = useState(cardClosing?.closing_date)
    const [dueDate, setDueDate] = useState(cardClosing?.due_date)
    const isNewCardClosing = !cardClosing || cardClosing?.id === -1
    const { fetch_api } = useFetch()
    
    useEffect(() => {
        if (isNewCardClosing) {
            setClosingDate(new Date().toISOString().substring(0,10))
            setDueDate(new Date().toISOString().substring(0,10))
        }
        else {
            setDueDate(cardClosing.due_date)
            setClosingDate(cardClosing.closing_date)
        }
    }, [cardClosing])

    const save = async () => {
        let url = isNewCardClosing ? `cards/${card?.id}/closings` : `cards/${card?.id}/closings/${cardClosing.id}`
        let method = isNewCardClosing ? "POST" : "PUT"
        let res = await fetch_api(url, {
            method: method, headers: {
                "Content-Type": "application/json"
            }, body: JSON.stringify({
                closing_date: closingDate,
                due_date: dueDate
            })
        })
        if (res.ok) {
            toast("Card Closing " + (isNewCardClosing ? "created" : "updated"), { type: "success" })
            onUpdate()
        }
    }

    return (
        <div className="modal modal-blur fade" id="card-closing-modal" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{!cardClosing || cardClosing?.id === -1 ? "New" : "Edit"} card closing</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body row">
                        <div className="col">
                            <label className="form-label">Closing date</label>
                            <input type="date" className="form-control" value={closingDate} onChange={(e) => setClosingDate(e.target.value)} placeholder="Card closing date" />
                        </div>
                        <div className="col">
                            <label className="form-label">Due date</label>
                            <input type="date" className="form-control" value={dueDate} onChange={(e) => setDueDate(e.target.value)} placeholder="Card due date" />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <a href="#" className="btn btn-link link-secondary" data-bs-dismiss="modal">
                            Cancel
                        </a>
                        <a href="#" onClick={() => save()} className="btn btn-primary ms-auto" data-bs-dismiss="modal">
                            <IconPlus className="icon" />
                            {!cardClosing || cardClosing?.id === -1 ? "Create" : "Edit"}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}